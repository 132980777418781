@import "./variables";

$baskerville: '../assets/fonts/libre-baskerville/';
$switzer: '../assets/fonts/switzer/';

@mixin sharedFontFaceProps() {
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Libre-Baskerville-Regular';
  src: url($baskerville + 'LibreBaskerville-Regular.otf') format('opentype');
  @include sharedFontFaceProps();
}

@font-face {
  font-family: 'Switzer-Light';
  src: url($switzer + 'Switzer-Light.woff2') format('woff2'),
       url($switzer + 'Switzer-Light.woff')  format('woff'),
       url($switzer + 'Switzer-Light.ttf')   format('truetype');
  @include sharedFontFaceProps();
}

@font-face {
  font-family: 'Switzer-Medium';
  src: url($switzer + 'Switzer-Medium.woff2') format('woff2'),
       url($switzer + 'Switzer-Medium.woff')  format('woff'),
       url($switzer + 'Switzer-Medium.ttf')   format('truetype');
  @include sharedFontFaceProps();
}

@font-face {
  font-family: 'Switzer-Regular';
  src: url($switzer + 'Switzer-Regular.woff2') format('woff2'),
       url($switzer + 'Switzer-Regular.woff')  format('woff'),
       url($switzer + 'Switzer-Regular.ttf')   format('truetype');
  @include sharedFontFaceProps();
}

@font-face {
  font-family: 'Switzer-Semibold';
  src: url($switzer + 'Switzer-Semibold.woff2') format('woff2'),
       url($switzer + 'Switzer-Semibold.woff')  format('woff'),
       url($switzer + 'Switzer-Semibold.ttf')   format('truetype');
  @include sharedFontFaceProps();
}

@font-face {
  font-family: 'Switzer-Bold';
  src: url($switzer + 'Switzer-Bold.woff2') format('woff2'),
       url($switzer + 'Switzer-Bold.woff')  format('woff'),
       url($switzer + 'Switzer-Bold.ttf')   format('truetype');
  @include sharedFontFaceProps();
}
