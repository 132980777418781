@import "../../../../styles/mixins.scss";

.testimonials {
  background-color: rgba(96, 108, 153, 0.1);  // #606C99;
  padding: 29px 0 0 0;
  position: relative;

  .testimonials__big-heading {
    @include headingFont(type24);
    margin-bottom: 0;
    @include _mq(intermediate) {
      @include headingFont(type14);
    }
  }

  .testimonials__after768 {
    @include _mq(intermediate) {
      margin: 80px 0 0 32%;
    }
  }
}