@import "../../../../styles/mixins.scss";

.intro {
  color: #fff;
  margin: 0 0 36px 0;

  p {
    max-width: 306px;
    @include bodyFont_37(regular);
    @include _mq(intermediate) {
      max-width: 348px;
    }
  }

  .intro__cta {
    margin: 40px 0 174px;

    button {
      margin: 0 auto;
      
      @include _mq(small) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}