.leader-image {
  height: 200px;
  margin-bottom: 40px;
  margin-top: -18px; // TODO don't place at top of page with negative margin
  overflow: hidden;
  position: relative;

  &:after {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    padding: 16px;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  img {
    height: auto;
    width: 100%;
  }

  .leader-image__title {
    color: white;
    left: 0;
    margin: 0;
    padding: 0 10%;
    position: absolute;
    top: 30px;
    z-index: 2;
  }
}
