@import "../../../../styles/mixins.scss";

.growth {
  background-color: #2251FF;
  color: #fff;
  padding: 50px 0 50px;
  position: relative;
  @include _mq(large) {
    padding-bottom: 100px;
  }
  @include _mq(xlarge) {
    margin: 66px 0 0 auto;
    max-width: 1320px;
    padding-top: 124px;
    position: relative;
    /* TODO figure out how clip-path works, this is good enough but not exact */
    clip-path: polygon(
      5% 10%,
      5% 0,
      100% 0%,
      100% 100%,
      100% 100%,
      0% 100%,
      0% 10%
    );
  }

  .growth__outer-container {
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    padding: 28px 0 0 0;
  }

  .growth__inner-container {
    margin: 0 auto;
    max-width: 97%; // FRAGILE see notes below

    @include _mq(intermediate) {
      max-width: 100%;
    }
  }

  h2 {
    margin: 0 0 50px 0;
    text-align: center;
    @include bodyFont_67();
    @include _mq(large) {
      margin-bottom: 70px;
      text-align: left;
    }
  }

  .growth__statistics {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 36px;
    @include _mq(intermediate) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
}

/*

NOTES

At 360px, `max-width` should be 311px but we also need `max-width` to adjust for other browser
widths. The solution is to calculate a percentage based on `.y-wrap--inner-alt`. For example,
if `.y-wrap-inner-alt` is set with a width of 89%, we know that at an overall width of 360px we
can set 311px, as follows: 

(e.g. 311px / (360px * .89))

This equals 97.1% and we can use this percentage for other browser widths. This is fragile, though,
because it depends on the width set in `.y-wrap-inner-alt` so if that values changes, then we must
change our calculation here, as well.

*/
