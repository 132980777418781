@import "../../styles/mixins.scss";

.accordion {
  border-bottom: 2px solid rgba(96, 108, 153, 0.5);  //#606C99

  .accordion__cta {
    margin: 25px 0 31px;
  }

  &.accordion--no-border {
    border-bottom: none;
  }

  p {
    margin: 2px 0 0 0;
    max-width: 87.2%; // FRAGILE, see notes below
    @include bodyFont_37(regular, #505A80);
  }

  .accordion__title-container {
    padding: 26px 0 26px 0;
    position: relative;
    @include bodyFont_67();

    .accordion__title {
      max-width: 88%;
    }
    .accordion__toggle {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-46%);  // not '-50%' because the text we align to has a value for `line-height`
    }
  }
}

/*

NOTES

At 360px, `max-width` should be 270px but we also need `max-width` to adjust for other browser widths.
The solution is to calculate a percentage based on `.y-wrap--inner`. For example, if `.y-wrap-inner`
is set with a width of 86%, we know that at an overall width of 360px we can set 270px, as follows: 

(e.g. 270px / (360px * .86))

This equals 87.2% and we can use this percentage for other browser widths. This is fragile, though, because
it depends on the width set in `.y-wrap-inner` so if that values changes, then we must change our
calculation here, as well.

*/
