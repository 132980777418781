@import "../../styles/mixins.scss";

.slider {

  .slider__navs-container {
    display: flex;
    justify-content: space-between;
  }

  .slider__navs {
    display: flex;
    justify-content: space-between;
    padding: 30px 0 30px 0;
  }

  .slider__navs-nav {
    bottom: 0;
    display: flex;
    position: absolute;
    right: 0px;
  }

  .slider__nav {
    padding: 30px 38px;

    svg {
      height: auto;
      width: 100%;
    }

    &.slider__nav--right {
      background-color: #FFF;
      padding-right: 22px;
    }
  }

  .count-container {
    @include bodyFont_57(boldSmall, #bbbfce);  // TODO comp calls for #505A80 but this does not match visually

    .count-container__position {
      color: #04162F;
    }

    .count-container__of {
      margin: 0 2px 0 6px;
    }
  }

  &.slider--projects {

    .slider__navs-container {
      bottom: 0;
      left: 0;
      position: absolute;
      width: 100%;
    }

    .slider__caption {
      color: #505A80;
      @include bodyFont_17(regular);
  
      .slider__caption-subtitle {
        color: #071033;
        margin: 9px 0 0 0;
        @include bodyFont_67();
      }
    }
  
    .slider__graphic {
      height: 255px;
      margin: 38px 0 34px 0;
      overflow: hidden;
      width: 100%;
  
      img {
        height: auto;
        width: 100%;
      }
    }

    @media screen and (min-width: 576px) {
      .slider__graphic {
        height: 406px;
      }
    }

    @media screen and (min-width: 768px) {
      .slider__graphic {
        height: 542px;
      }
    }
  }

  &.slider--headshots {

    .slider__caption {
      border-left: 1px solid #606C99;
      color: #071033;
      margin: 20px 0 10px 0;
      padding: 6px 0 0 22px;

      .slider__caption-title {
        margin: 0 0 5px 0;
        @include bodyFont_57(bold)
      }
  
      .slider__caption-subtitle {
        color: #505A80;
        @include bodyFont_17(regular)
      }
    }
  
    .slider__graphic {
      margin: 38px 0 31px 0;
      position: relative;
      overflow-x: hidden;

      .slider__graphic-subcontainer {
        display: flex;
      }
  
      img {
        height: 125%;
        margin: -27px 0 0 -25px;
        width: auto;
      }

      .slider__img-container {
        height: 100%;
        overflow: hidden;
        max-height: 225px;
        max-width: 179px;
      }

      .slider__position {
        margin: 0 20px;

        &.slider__position--0 {
          margin-left: 0;
        }
      }
    }
    .slider__navs-container {
      width: 100%;
    }
  }



  &.slider--teasers {

    .slider__navs-nav {
      @include _mq(intermediate) {
        background-color: white;
        padding-right: 90px;
      }
    }

    .slider__caption {
      color: #071033;
      margin: 20px 0 10px 0;
      padding: 6px 0 0 22px;
      @include _mq(intermediate) {
        padding-left: 0;
      }

      .slider__caption-title {
        margin: 0 0 5px 0;
        @include bodyFont_17(regular, #505A80)
      }
  
      .slider__caption-subtitle {
        @include bodyFont_67(#071033)
      }
    }
  
    .slider__graphic {
      margin: 38px 0 31px 0;
      position: relative;
      overflow-x: hidden;
      @include _mq(intermediate) {
        margin-bottom: 80px;
      }

      .slider__graphic-subcontainer {
        display: flex;
      }
  
      img {
        // height: 125%;
        // margin: -27px 0 0 -25px;
        // width: auto;
      }

      .slider__img-container {
        align-items: center;
        background-color: #ddd;
        display: flex;
        height: 100%;
        justify-content: center;
        overflow: hidden;
        height: 450px;
        padding: 6px;
        width: 500px;
        @include _mq(xlarge) {
          max-height: 600px;
          max-width: 425px;
        }

        &.slider__img-container--horizontal img {
          height: auto;
          width: 100%;
        }

        &.slider__img-container--vertical img {
          height: 100%;
          width: auto;
        }
      }

      .slider__position {
        margin: 0 20px;

        &.slider__position--0 {
          margin-left: 0;
        }
      }
    }
    .slider__navs-container {
      width: 100%;
    }
  }





  &.slider--texts {

    .slider__testimonial-author {
      @include bodyFont_57(bold);
    }
    
    .slider__testimonial-quote {
      @include bodyFont_77();
      margin: 39px 0 47px 0;
    }
    .slider__testimonial-title {
      @include bodyFont_37(regular, #505A80);
      margin: 9px 0 38px 0;
    }
  }
}
