@import "../../../../styles/mixins.scss";

.business {
  padding: 29px 0 12px 0;

  .business__big-heading {
    @include headingFont(type24);
    margin-bottom: 0;
  }

  .business__cta {
    margin: 44px 0 50px;

    button {
      margin: 0 auto;

      @include _mq(small) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  p {
    @include bodyFont_37(regular);
    margin-top: -4px;
    max-width: 306px;
  }

  .business__img-container {
    height: 200px;
    overflow: hidden;
    position: relative;
    width: 100%;

    &:after {
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      @include imageOverlay(0.6);
    }

    img {
      height: auto;
      margin-top: -39px;
      width: 123%;
    }
  }

  @media screen and (min-width: 576px) {
    .business__img-container {
      height: 316px;

      img {
        margin-top: -65px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .business__img-container {
      height: 270px;
      width: 348px;

      &:before {
        background-color: #fff;
        content: '';
        height: 48px;
        position: absolute;
        right: 0;
        top: 0;
        width: 48px;
        z-index: 30;
      }

      img {
        margin-top: -4px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .business__img-container {
      height: 425px;
      width: 580px;

      &:before {
        height: 80px;
        width: 80px;
      }

      img {
        margin-top: -10px;
      }
    }
  }

  &.business--intermediate {
    .b-container {
      display: flex;
      flex-direction: column;

      .b-container__cta {
        margin: 38px 0 48px;
      }

      .b-container__main {
        .b-container__big-heading {
          margin: 0 0 80px 0;
          @include headingFont(type14);
        }
      }

      .b-container__secondary {
        display: flex;
        justify-content: space-between;

        p {
          margin: -140px 0 0 0;
          padding: 0 0 0 50px;
          @include bodyFont_57(regular, #505A80);
        }
      }
    }
  }
}

