//----------------------------------------------------------------------------
//	HTML5 Reset
//----------------------------------------------------------------------------
abbr,
address,
article,
aside,
audio,
blockquote,
b,
body,
caption,
cite,
code,
del,
dd,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

article,
aside,
details,
figure,
footer,
header,
nav,
section,
summary {
  display: block;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

//----------------------------------------------------------------------------
//	Anchors
//----------------------------------------------------------------------------
a {
  background: transparent;
  color: inherit;
  cursor: pointer;
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline;
  &:active,
  &:hover {
    text-decoration: none;
  }
}
a:link {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

//----------------------------------------------------------------------------
//	Clearfix
//----------------------------------------------------------------------------
.cf:before,
.cf:after {
  content: " ";
  display: table;
}
.cf:after {
  clear: both;
}

//----------------------------------------------------------------------------
//	Formatting
//----------------------------------------------------------------------------
abbr[title],
dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}
code,
kbd,
pre,
samp {
  font-family: monospace, sans-serif;
}
del {
  text-decoration: line-through;
}
ins {
  background-color: #000;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #000;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  white-space: pre-line;
  word-wrap: break-word;
}
small {
  font-size: 85%;
}
strong {
  font-weight: bold;
}

//----------------------------------------------------------------------------
//	Images
//----------------------------------------------------------------------------
.ie7 img {
  -ms-interpolation-mode: bicubic;
}
img {
  display: block;
  height: auto;
  width: 100%;
}
td img {
  vertical-align: top;
}

//----------------------------------------------------------------------------
//	Input Elements
//----------------------------------------------------------------------------
.clickable,
label,
input[type="button"],
input[type="submit"],
input[type="file"],
button {
  cursor: pointer;
}
button,
input,
select,
textarea {
  margin: 0;
}
button,
input[type="button"] {
  width: auto;
  overflow: visible;
}
input,
select {
  font: 99% sans-serif;
  vertical-align: middle;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #a9a9a9;
}
::-moz-selection,
::selection {
  background: thistle;
  text-shadow: none;
}
input,
input:focus {
  outline: none;
}
.ie7 input[type="checkbox"] {
  vertical-align: baseline;
}
input[type="radio"] {
  vertical-align: text-bottom;
}
input[type="checkbox"] {
  vertical-align: bottom;
}
textarea {
  font: 99% sans-serif;
}

//----------------------------------------------------------------------------
//	Lists
//----------------------------------------------------------------------------
ul {
  list-style: none;
}

//----------------------------------------------------------------------------
//	Miscellaneous
//----------------------------------------------------------------------------
hr {
  border: 0;
  border-top: 1px solid #ccc;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}
html {
  // -moz-osx-font-smoothing: grayscale;
  // -webkit-font-smoothing: antialiased;
}

//----------------------------------------------------------------------------
//	Quotes
//----------------------------------------------------------------------------
blockquote {
  quotes: none;
  &:after,
  &:before {
    content: "";
    content: none;
  }
}
q {
  quotes: none;
  &:after,
  &:before {
    content: "";
    content: none;
  }
}

//----------------------------------------------------------------------------
//	Subscripts and Superscripts
//----------------------------------------------------------------------------
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}

//----------------------------------------------------------------------------
//	Tables and Table Elements
//----------------------------------------------------------------------------
td {
  font-weight: normal;
  vertical-align: top;
}
th {
  font-weight: bold;
  vertical-align: bottom;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  font: 100%;
  font-size: inherit;
}
