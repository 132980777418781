@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

// `border` is set app-wide (see styles/base.scss)
// margins should be included in the `className` prop that is passed in
.cta {
  align-items: center;
  background-color: transparent;  // default
  border-radius: 80px;
  display: flex;
  justify-content: space-between;
  padding: 17px 22px;  // default  // TODO determine whether we need this since we are also setting it inline
  width: 306px; // default
  @include bodyFont_47(semibold);

  &.cta--compact {
    @include bodyFont_47(semiboldCompact);
  }

  &.cta--blue {
    background-color: $white;
    color: $blue;
  }

  &.cta--white {
    background-color: $blue;
    color: $white;
  }
}

// TODO
// update this comment, some of it may no longer apply --> `background-color`, `color`, `font-size`, `padding`, and `width` are set inline since CTA buttons can vary
// `color` is passed in to customize the button, consider finding another solution