@import '../../styles/mixins';

.footer {
  background-color: rgb(20, 49, 153);
  color: #fff;
  padding: 62px 0 48px;

  a,
  span {
    display: block;
  }

  address {
    font-style: normal;
  }

  .footer__get-started {
    padding: 0 0 40px 0;

    .footer__big-heading {
      @include headingFont(type24);
      margin: 0 0 33px 0;
    }

    p {
      @include bodyFont_37(regular);
    }
  }

  .footer__your-name {

    &.your-name {
      input.your-name__heading  {
        background-color: inherit;
        border: 1px solid transparent;
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
        padding: 6px 14px 6px 0;
        width: 100%;
        &::placeholder {
          color: rgba(255, 255, 255, 1);
        }
        @include bodyFont_67();
      }
      .your-name__cta {
        margin: 51px 0;
      }
    }
  }

  .footer__logo-with-scroll {
    align-items: center;
    border-top: 2px solid rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: space-between;
    margin: 50px 0 50px 0;
    padding: 46px 0 0 0;

    .footer__cta-container {
      height: 60px;
      width: 60px;
      svg {
        height: auto;
        transform: rotate(-45deg);
        width: 100%;
      }
    }
  }

  .footer__contact-container {
    margin: 50px 0 0 0;
    &.contact-container {
      .contact-container__heading {
        @include bodyFont_67();
      }

      address {
        margin: 41px 0;
        @include bodyFont_37(regular);

        &.heavyish {
          @include bodyFont_37(medium);
        }
      }
    }
  }

  .footer__navs-container {
    margin: 48px 0 78px 0;
    @include bodyFont_67();

    a {
      margin: 23px 0;
      @include _mq(intermediate) {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .footer__credits {
    @include bodyFont_27(regular);

    a {
      display: initial;
    }
    p {
      margin: 0;
    }
  }

  .footer__links-container {
    @include _mq(intermediate) {
      display: flex;
      justify-content: space-between;
    }
  }
}
