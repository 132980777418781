.y-wrap {
  &.y-wrap--inner {
    margin: 0 auto;
    width: 86%;
  }
  &.y-wrap--inner-alt {
    margin: 0 auto;
    width: 89%;
  }
  
  @media screen and (min-width: 768px) {
    &.y-wrap--after768 {
      margin: 0 auto;
      width: 89%;
    }
  }
}

.y-header-mobile {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.y-sticky {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
}

.y-disable {
  position: relative;
}

.y-disable:after {
  content: "";
  height: 100%;
  width: 100%;
  z-index: 15;
}

.y-align {
  display: flex;
  height: 100%;
  &.y-align--hv {
    justify-content: space-between;
    align-items: center;
  }
}

.y-absolute {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
}

.y-flex {
  display: flex;
}

.y-outer-wrap {
  background-color: $white;
  margin: 0 auto;
  max-width: 1440px;
  overflow: hidden;
  position: relative;
  z-index: -5;
}