@import '../../../../styles/mixins';

.credentials {
  background-color: rgb(120, 123, 133);
  color: #FFF;
  padding: 30px 0 64px;

  .credentials__big-heading {
    @include headingFont(type24);
    margin: 0 0 50px 0;
  }

  .credentials__h4 {
    margin: 0 0 12px 0;
    @include headingFont(type14, #B6BAC6);
  }

  .credentials__licenses {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    @include bodyFont_67();
  }

  .credentials__license {
    margin: 6px 0 0 0;
    @include bodyFont_57(regular);
  }

  .subheader.subheader--white {
    border-top: 2px solid rgba(255, 255, 255, 0.5);
    color: #FFF;
  }

  .credentials__logos {
    display: flex;
    flex-direction: column;
    margin: 68px 0 0 0;
    @include _mq(intermediate) {
      margin-top: 0;
    }
    @include _mq(large) {
      padding-right: 60px;
    }
  }

  .credentials__logo {
    width: 213px;

    &.credentials__logo--ashe {
      margin: 0 0 12px 0;
      width: 185px;
    }

    &.credentials__logo--chc {
      margin: 0 13px 12px 0;
      width: 118px;
    }

    &.credentials__logo--amredcross {
      margin: 50px 19px 0 0;
      width: 205px;
    }

    &.credentials__logo--chc,
    &.credentials__logo--amredcross {
      align-self: flex-end;
      @include _mq(intermediate) {
        align-self: flex-start;
      }
    }

    img {
      height: auto;
      width: 100%;
    }
  }

  .credentials__container {
    @include _mq(intermediate) {
      display: flex;
      justify-content: space-between;
    }
  }

  .logos {
    .logos__logos-container {
      display: flex;
      flex-direction: column;
      @include _mq(intermediate) {
        height: 100%;
        justify-content: space-between;
      }
    }
  }
}

// TODO clean up style rules so that everything here is easier to follow
