@import "../../styles/mixins.scss";

.caption {
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  .city-state {
    @include bodyFont_17(regular40p);
  }

  .title {
    @include bodyFont_57(bold);
  }
}