//----------------------------------------------------------------------------
//  Body
//----------------------------------------------------------------------------
body {
  background-color: #071033;
  min-height: 100%;
  position: relative;
  z-index: -10;
}

//----------------------------------------------------------------------------
//  HTML
//----------------------------------------------------------------------------
html {
  height: 100%;
  scroll-behavior: smooth;
}

//----------------------------------------------------------------------------
//  Headings
//----------------------------------------------------------------------------
h1 {
  margin: 0 0 18px 0;
  @include headingFont(type24);
  @include _mq(large) {
    @include headingFont(type44);
  }
}

//----------------------------------------------------------------------------
//  Paragraphs
//----------------------------------------------------------------------------
p + p {
  margin-top: 12px;
}

//----------------------------------------------------------------------------
//  Form, Input, and Button
//----------------------------------------------------------------------------
button {
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0;
}