.content-block {
  margin: 20px 0 40px;
  position: relative;
}

.contact-block {
  span {
    display: block;
  }
}

.temp-btn-replacement {
  color: crimson;

  background-color: $white;
  margin: 40px 0 174px;
}