@import "../../styles/mixins.scss";

.subheader {
  border-top: 2px solid rgba(96, 108, 153, 0.3);
  color: #2251FF;
  display: flex;
  margin: 20px 0 0 0;
  padding: 28px 0 0 0;

  h2 {
    margin: 0 0 50px 0;
    @include bodyFont_67();
  }

  span {
    margin: 0 22px 0 0;
    @include bodyFont_27(regular);
  }

}