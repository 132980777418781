@import "../../../../styles/mixins.scss";

.team {
  padding: 29px 0 0 0;
  position: relative;

  // TODO remove this after site is fully functional
  @include _mq(large) {
    padding-bottom: 80px;
  }

  .team__big-heading {
    @include headingFont(type24);
    margin-bottom: 0;
  }

  .not-mobile-team {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
      .blah {
        flex: 1 0 auto;
        max-width: 24%;
        @include _mq(large) {
          margin: 20px 0 30px 0;
        }
        .slider__caption {
          border-left: 1px solid #606C99;
          color: #071033;
          margin: 20px 0 10px 0;
          padding: 6px 0 0 22px;

          .slider__caption-title {
            margin: 0 0 5px 0;
            @include bodyFont_57(bold);
          }
      
          .slider__caption-subtitle {
            color: #505A80;
            @include bodyFont_17(regular);
          }
        }
      }
  }
}
