.contact-block {
  .contact-block__field {
    margin: 20px 0;
  }

  button {
    border: 1px solid #aaa;
    padding: 8px 20px;
  }

  input,
  textarea {
    border: 1px solid #aaa;
    padding: 8px 20px 8px 10px;
    width: 100%;

    &::placeholder {
      color: #aaa;
    }
  }

  textarea {
    min-height: 200px;
  }

  .contact-block__button {
    margin-top: 40px;
    text-align: center;
  }
}
