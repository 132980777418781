@import "../../styles/mixins.scss";

.statistic {
  &:nth-of-type(odd) {
    width: 60%;
    @include _mq(intermediate) {
      width: auto;
    }
  }
  .statistic__stat {
    display: block;
    margin: 0 0 19px 0;
    @include headingFont(type34);
    @include _mq(large) {
      @include headingFont(type44);
    }
  }

  .statistic__text {
    max-width: 122px;
    @include bodyFont_27(regular, #071033);
  }
}
