@import "../../../../styles/mixins.scss";

.projects {
  background-color: rgba(96, 108, 153, 0.1);  // #606C99;
  padding: 29px 0 112px 0;
  position: relative;
  @include _mq(intermediate) {
    padding-bottom: 0;
  }

  .projects__big-heading {
    @include headingFont(type24);
    margin-bottom: 0;
    @include _mq(intermediate) {
      margin: 0 0 70px 0;
      @include headingFont(type14);
    }
    @include _mq(xlarge) {
      margin-bottom: 108px;
    }
  }
}