//----------------------------------------------------------------------------
//  Colors
//----------------------------------------------------------------------------
$black:	#071033;
$blue:	#2251FF;
$gray:	#B6BAC6;
$midnight: #04162F;
$slate:	#505A80;
$white:	#FFFFFF;

//----------------------------------------------------------------------------
//  Font Weights
//----------------------------------------------------------------------------
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
