.modal {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  left: 50%;
  overflow: scroll;
  padding: 60px 30px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  z-index: 100;

  .modal__button {
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    font-size: 2.5rem;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
