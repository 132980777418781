@import "../../../../styles/mixins.scss";

.our-process {
  padding: 17px 0 0 0;

  .our-process__cta {
    margin: 31px 0 48px;

    button {
      margin: 0 auto;

      @include _mq(small) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    
    &.our-process__cta--last {
      margin-top: 64px;
    }
  }

  .our-process__big-heading {
    @include headingFont(type24);
    margin-bottom: 0;
  }

  .our-process__accordions {
    border-top: 2px solid rgba(7, 16, 51, 0.7); // #071033;
    margin: 44px 0 0 0;
  }

  .our-process__img-container {
    height: 200px;
    overflow: hidden;
    position: relative;
    width: 100%;

    &:after {
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      @include imageOverlay(0.6);
    }

    img {
      height: auto;
      margin: -340px 0 0 -60px;
      width: 140%;
    }
  }

  @media screen and (min-width: 576px) {
    .our-process__img-container {
      height: 310px;

      img {
        margin: -550px 0 0 -96px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .our-process__img-container {
      height: 300px;
      width: 215px;

      &:before {
        background-color: #fff;
        content: '';
        height: 38px;
        left: 0;
        position: absolute;
        top: 0;
        width: 38px;
        z-index: 30;
      }

      img {
        margin: -88px 0 0 -38px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .our-process__img-container {
      height: 601px;
      width: 425px;

      &:before {
        height: 80px;
        width: 80px;
      }

      img {
        margin: -174px 0 0 -78px;
      }
    }
  }

  &.our-process--intermediate {
    .op-container {
      display: flex;
      justify-content: space-between;

      .op-container__image {
        margin: 120px 30px 0 0;
      }
    }
    .our-process__big-heading {
      @include headingFont(type14);
    }
  }
}
