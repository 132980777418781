.svg-icon {
  &.svg-icon__logo {
    width: 137px;
  }

  &.svg-icon--download-pdf {
    // TODO remove path highlighting (this should only apply to the map)
    cursor: pointer;
    height: auto;
    width: 50px;
    path,
    polygon {
      fill: #555;
    }
  }

  svg {
    height: auto;
    width: 100%;

    path:hover,
    polygon:hover {
      cursor: pointer;
      fill: #4576ad;
    }

    path.selected,
    polygon.selected {
      fill: #4576ad;
    }
  }
}
