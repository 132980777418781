header {
  margin: 0 0 66px 0;
  padding: 17px 0 25px;
  width: 100%;

  &.border-lt {
    border-bottom: 1px solid #ffffff;
  }

  &.border-dk {
    border-bottom: 1px solid #071033;
  }
}
