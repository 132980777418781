@import "./variables";

//----------------------------------------------------------------------------
//  Media Queries
//----------------------------------------------------------------------------
@mixin _mq($size) {
  @if       $size == xsmall       { @media only screen and (min-width: 360px)  { @content; } }
  @else if  $size == small        { @media only screen and (min-width: 576px)  { @content; } }
  @else if  $size == intermediate { @media only screen and (min-width: 768px)  { @content; } }
  @else if  $size == large        { @media only screen and (min-width: 992px)  { @content; } }
  @else if  $size == xlarge       { @media only screen and (min-width: 1200px) { @content; } }
}


@mixin imageOverlay($opacity: 0.8) {
  // TODO use a conditional with names to handle rgba
  background-color: rgba(13, 35, 115, $opacity); /* TODO get the right specs from Amelia, this is a guess */
  content: '';
  position: absolute;
}


//----------------------------------------------------------------------------
//  BODY FONTS
//----------------------------------------------------------------------------
// type can be `regular` or `regular40p`
@mixin bodyFont_17($type, $color: inherit) {
  color: $color;
  font-family: 'Switzer-Regular';
  font-size: 14px;
  font-weight: $regular;
  line-height: 16.8px;
  @if      $type == regular    { /* use default styles defined above */ }
  @else if $type == regular40p { opacity: 0.4; }
  @else                        { color: crimson; }  // indicates there is a problem
}

// type can be `bold`, `semibold`, `regular`, or `other`
@mixin bodyFont_27($type, $color: inherit) {
  color: $color;
  font-size: 14px;
  @if      $type == bold     { font-family: 'Switzer-Bold'; font-weight: $bold; letter-spacing: 4%; line-height: 18.48px; }
  @else if $type == semibold { font-family: 'Switzer-SemiBold'; font-weight: $semibold; line-height: 22.4px; }
  @else if $type == regular  { font-family: 'Switzer-Regular'; font-weight: $regular; line-height: 19.6px; }
  @else if $type == other    { line-height: 16.8px; opacity: 0.4; }
  @else                      { color: crimson; }  // indicates there is a problem
}

// type can be `medium`, or `regular`
@mixin bodyFont_37($type, $color: inherit) {
  color: $color;
  font-size: 16px;
  line-height: 25.6px;
  @if      $type == semibold { font-family: 'Switzer-SemiBold'; font-weight: $semibold; letter-spacing: -0.4px; }
  @else if $type == medium   { font-family: 'Switzer-Medium'; font-weight: $medium; }
  @else if $type == regular  { font-family: 'Switzer-Regular'; font-weight: $regular; }
  @else                      { color: crimson; }  // indicates there is a problem
}

// type can be `semibold`, or `semiboldCompact`
@mixin bodyFont_47($type, $color: inherit) {
  color: $color;
  font-family: 'Switzer-SemiBold';
  font-size: 16px;
  font-weight: $semibold;
  @if      $type == semibold        { line-height: 22.4px; }
  @else if $type == semiboldCompact { letter-spacing: -0.4px; line-height: 25.6px; }
  @else                             { color: crimson; }  // indicates there is a problem
}

// type can be `bold`, `boldSmall`, or `regular`
@mixin bodyFont_57($type, $color: inherit) {
  color: $color;
  font-family: 'Switzer-Bold';
  font-size: 18px;
  font-weight: $bold;
  line-height: 28.8px;
  @if      $type == bold      { /* use default styles defined above */ }
  @else if $type == boldSmall { font-size: 14px; letter-spacing: 0.04em; line-height: 18.48px; }
  @else if $type == regular   { font-family: 'Switzer-Regular'; font-weight: $regular; }
  @else                       { color: crimson; }  // indicates there is a problem
}

// bodyFont_67 is always `bold`
@mixin bodyFont_67($color: inherit) {
  color: $color;
  font-family: 'Switzer-Bold';
  font-size: 22px;
  font-weight: $bold;
  line-height: 26.4px;
}


// bodyFont_77 is always `bold`
@mixin bodyFont_77($color: inherit) {
  color: $color;
  font-family: 'Switzer-Bold';
  font-size: 28px;
  font-weight: $bold;
  line-height: 39.2px;
}


//----------------------------------------------------------------------------
//  HEADING FONT
//----------------------------------------------------------------------------

// type can be `type14`, `type24`, `type34`, or `type44`
@mixin headingFont($type, $color: inherit) {
  color: $color;
  font-family: 'Libre-Baskerville-Regular';
  font-weight: $regular;
  letter-spacing: -0.04em;
  @if      $type == type14 { font-size: 40px; line-height: 56px; }
  @else if $type == type24 { font-size: 45px; line-height: 54px; }
  @else if $type == type34 { font-size: 64px; line-height: 76.8px; }
  @else if $type == type44 { font-size: 80px; line-height: 80px; }
  @else                    { color: crimson; }  // indicates there is a problem
}
