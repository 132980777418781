//----------------------------------------------------------------------------
//  Form
//----------------------------------------------------------------------------

.signup {
  margin: 64px 0;

  h2 {
    text-transform: uppercase;
  }

  label {
    color: #999;
  }
}

.signup--identity {
  flex: 1;
}

.signup__input-container {
  flex: 1;
  margin-bottom: 16px;

  &.signup__input-container--many {
    flex: 100%;
  }
}

.signup__bottom-area {
}

.signup__radio-container {
  display: flex;
  justify-content: space-between;
  text-align: center;

  .input-block {
    border: 1px solid #bbb;
    overflow: hiddden;
    width: 30%;
  }

  &.signup__radio-container--many {
    display: flex;
    flex-direction: column;

    .input-block {
      margin: 4px 0;
      max-width: 260px;
      width: 50%;
    }
  }

  .signup__radio-label {
    // align-items: center;
    // display: flex;
    // flex-direction: row-reverse;
    // justify-content: space-between;
    input {
      width: 20px;
    }
  }
}

.signup__select-container {
  display: flex;
  justify-content: space-between;

  select {
    width: 140px;
  }
}

.signup--sponsors {
  p {
    margin-bottom: 16px;
  }
  textarea {
    min-height: 200px;
    width: 100%;
  }
}

.signup__verify {
  background-color: #f5f5f5;
  margin: 20px 0;
  padding: 20px;
  p {
    margin: 10px;
  }
}

.signup__edit {
  align-items: center;
  display: flex;
  justify-content: space-between;

  button {
    max-width: 60px;
    padding: 4px 8px;
  }
}

.form-field {
  border: 1px solid #666;
  padding: 6px 12px;

  &.form-field--invalid {
    background-color: rgba(
      220,
      20,
      60,
      0.4
    ); // TODO find a better danger color, this is crimson is 40% opacity
    border: 1px solid transparent;
  }

  &.form-field--hidden {
    opacity: 0;
    position: fixed;
  }
}

.input-block {
  input:checked + label {
    background: #e3841e;
    color: white;
    display: block;
  }
}
