@import '../../styles/mixins';

.stats {
  margin: -120px 0 0 20px;

  h3 {
    text-transform: uppercase;
  }

  ul {
    margin-top: 10px;
  }
}

// TODO this is very FRAGILE, find a better way
.banner-container {
  overflow: hidden;
  @include _mq(xlarge) {
    &:before {
      background-color: #122664;
      content: '';
      height: 1404px;
      left: -25%;
      position: absolute;
      top: 0;
      width: 50%;
      z-index: -1;
    }
  }
}

.banner {
  display: flex;
  height: 1050px;
  justify-content: center;
  left: 0;
  max-width: 1440px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  @include _mq(large) {
    height: 960px;
  }

  // `background-color`, `content`, and `position` are set by the `imageOverlay` mixin
  &:after {
    height: 120%;
    left: -10%;
    top: -10px;
    width: 120%;
    @include imageOverlay(0.5);
  }

  img {
    height: 100%;
    margin: -3px 0 0 -54px;
    width: auto;
  }
}